import React, { useCallback, useEffect, useState } from "react";
import "./RecentPatients.css";
import { GoTriangleRight } from "react-icons/go";
import RecentPatientsTable from "./RecentPatientsTable/RecentPatientsTable";
import { getRecentPatients } from "../../../../../utils/appointmentsUtils/appointmentsUtils";
import { useNavigate } from "react-router-dom";

const RecentPatients = ({user}) => {
  const [appointments, setAppointments] = useState([]);
  const setAppointmentsFn = useCallback((mode = null) => {
    getRecentPatients(user?._id).then(res => {setAppointments(res.data.data.length ? res.data.data.slice(0,3) : [])}).catch(err => console.log(err));
  }, [user?._id]);
  useEffect(() => {
    setAppointmentsFn();
  }, [setAppointmentsFn]);
  const navigate = useNavigate();
  return (
    <div className={`recent-patients-container ${appointments.length > 0 ? 'flex' : 'hidden'} flex-col`}>
      <div className="rp-header">
        <div className="flex font-semibold text-[#000]">Recent Patients</div>
        <div className="rp-link">
          <span onClick={() => navigate(`/appointments`)}>View All</span>
          <span>
            <GoTriangleRight />
          </span>
        </div>
      </div>
      <RecentPatientsTable appointments={appointments} />
    </div>
  );
};

export default RecentPatients;
