import { axiosInstance } from "../../api/config";

export const getOnlineAppointments = async (doctorId) => {
    try {
        const response = await axiosInstance.get(`/onlineappointments/${doctorId}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const updateAppointment = async (appointmentId, status) => {
    try {
        const response = await axiosInstance.put(`/appointment/update_appointment/${appointmentId}`, { isVerified: status }, { headers: { "Content-Type": "application/json" } });
        return response;
    } catch (error) {
        throw error;
    }
}

export const setSchedule = async (initialSatate, appointmentId) => {
    try {
        const requestData = {
            schedule: {
                date: initialSatate.date,
                availableTime: initialSatate.availableTime,
                remindTime: initialSatate.remindTime,
                reason: initialSatate.reason || null
            }
        }
        const requestConfig = {
            headers: { "Content-Type": "application/json" },
        };
        const response = await axiosInstance.put(`/appointment/update_appointment/${appointmentId}`, requestData, requestConfig);
        return response
    } catch (error) {
        throw error;
    }
}

// export const addTest = async (initialState) => {
//     try {
//         const requestData = {
//             test_name: initialState.name,
//             patient_id: initialState.patient_id,
//             support_info: initialState.supportInfo,
//             priority: initialState.priority,
//             test_reason: initialState.testReason,
//             date: initialState.date,
//             time: initialState.time,
//             reasons: initialState.reasons,
//         };

//         const requestConfig = {
//             headers: { "Content-Type": "application/json" },
//         };

//         const response = await axiosInstance.post(`/doctor/add_doctortest`, requestData, requestConfig);
//         return response;
//     } catch (error) {
//         return error;
//     }
// }

// export const deleteTest = async (testId) => {
//     try {
//         const response = await axiosInstance.delete(`/doctor/delete_doctortest/${testId}`);
//         return response;
//     } catch (error) {
//         return error;
//     }
// }

export const getRequests = async (doctorId) => {
    try {
        const response = await axiosInstance.get(`/unverify_appointment/${doctorId}`);
        return response;
    } catch (error) {
        throw error;
    }
}