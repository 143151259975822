import React from 'react'

const AppointmentMode = ({selected,setSelected}) => {
  return (
        <div className="flex font-semibold gap-3 text-non_selected cursor-pointer">
          <span className={`${selected === "Today" && "text-primary"  }`}  onClick={()=>{setSelected("Today")}}>Today</span>
          <span className="text-black">|</span> 
          <span className={`${selected === "Completed" && "text-primary"}`} onClick={()=>{setSelected("Completed")}}>Completed</span>
          <span className="text-black">|</span>
          <span className={`${selected === "New" && "text-primary"}`}  onClick={()=>{setSelected("New")}}>New</span>
        </div>
  )
}

export default AppointmentMode