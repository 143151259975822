import React, { useMemo } from "react";
import "./NavBar.css";
import {BlackBarSvg} from "../../svg";
import { NavLink } from "react-router-dom";
import Earnings from "./Earnings/Earnings";
// import { Notification } from "./Notification/Notfication";
import { useSelector } from "react-redux";
import { NavDrop } from "./NavDrop/NavDrop";


const NavBar = () => {
  const  user  = useSelector((state) => state.userReducer.doctor);
  const links = useMemo(() => [
    { to: "/", destination: "Dashboard" },
    { to: "/appointments", destination: "Appointments" },
    { to: "/my-patients", destination: "My Patients" },
    { to: "/online-appointment", destination: "Online Appointment" },
  ], []);
  return (
    <>
      <div className="navbar">
        <div className="logo-container">
          <div className="logo">
            <img
              src="/assets/logo/life_plus_logo.png"
              alt="life_plus_logo"
            />
          </div>
          <BlackBarSvg />
        </div>
        <div className="navlinks ">
          {links.map(({ to, destination }, index) => (
            <NavLink to={to} key={index} className="navlink-class">
              <div>{destination}</div>
              <div className="active-dot"></div>
            </NavLink>
          ))}
        </div>
        <div className="earnings-container">
          {/* <Earnings /> */}
          {/* <div className="cursor-pointer"><Notification user={user}/></div> */}
          <NavDrop user={user} links={links}/>
        </div>
      </div>
    </>
  );
};

export default NavBar;
