import React from 'react'

const AppointedUser = ({
  name,
  time,
}) => {
  return (
    <div className="ta-user-container">
      <div className="ta-user-detail-container">
        <div className="ta-user-content">
          <span className="text-sm font-semibold">{name}</span>
        </div>
        <div className="text-xs font-semibold w-full flex justify-end">{time}</div>
      </div>
    </div>
  );
};

export default AppointedUser