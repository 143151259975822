import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as echarts from "echarts";
import "./ChartSection.css"
import SimpleDropdown from "../Patients/SimpleDropDown";
import { getGenderCount } from "../../../../../utils/appointmentsUtils/appointmentsUtils";

const ChartSection = () => {
  const [genderCounts, setGenderCounts] = useState({ male: 0, female: 0 });
  const setGenderCountFn = useCallback(() => {
    getGenderCount().then(res => setGenderCounts(res.data.data)).catch(err => console.log(err));
  }, [])
  useEffect(() => setGenderCountFn(), [setGenderCountFn]);
  const option = useMemo(() => {
    return {
      title: {
        left: "center",
        top: "center",
      },
      series: [
        {
          type: "pie",
          data: [
            {
              value: genderCounts.male ,
              name: "Male",
              itemStyle: { color: "#FFA901" },
            },
            {
              value: genderCounts.female,
              name: "Female",
              itemStyle: { color: "#7A6EFE" },
            },  
          ],
          radius: ["50%", "70%"],
        },
      ],
    };
  }, [genderCounts]);
  useEffect(() => {
    const chart = echarts.init(document.getElementById("doughnut-chart"));
    chart.setOption(option);
    window.addEventListener("resize", () => {
      chart.resize();
    });

    return () => {
      chart.dispose();
    };
  }, [option]);

  return (
    <div className="chart-section-container">
      <div className="chart-section-header">
        <div className="flex font-semibold">Gender</div>
      </div>
      <div
        className="chart-container"
        id="doughnut-chart"
        style={{ width: "100%", height: "398px" }}
      />
    </div>
  );
};

export default ChartSection;
