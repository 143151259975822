import React, { useCallback, useEffect, useState } from 'react'
import AppointmentsTable from '../AppointmentsTable/AppointmentsTable';
import AppointmentMode from '../AppointmentMode/AppointmentMode';
import { NoDataFound, SearchInput } from '../../Common';
import { getAppointments } from '../../../utils/appointmentsUtils/appointmentsUtils';
import { useSelector } from 'react-redux';
import MyPatientsFollowupModal from '../../MyPatientsComponents/MyPatientsTestModal/MyPatientsFollowupModal';
import MyPatientsPrescripitonModal from '../../MyPatientsComponents/MyPatientsTestModal/MyPatientsPrecripitonModal';
import MyPatientsTestModal from '../../MyPatientsComponents/MyPatientsTestModal/MyPatientsTestModal';

const AppointmentsMainSection = () => {
  const  user  = useSelector((state) => state.userReducer.doctor);
  const [selected, setSelected] = useState("Today");
  const [appointments, setAppointments] = useState([]);
  const setAppointmentsFn = useCallback(() => {
    getAppointments(user?._id, selected).then(res => {setAppointments(res.data.data)}).catch(err => console.log(err));
  }, [selected, user?._id]);
  useEffect(() => {
    setAppointmentsFn();
  }, [setAppointmentsFn]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const filteredData = appointments?.filter((patient) => {
      const nameMatch = patient?.name?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const pIdMatch = patient?.patient_id?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const vIdMatch = patient?.visit_id?.toLowerCase()
        ?.includes(searchQuery.toLowerCase());
      const diseaseMatch = patient?.disease?.toString()?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      return nameMatch || pIdMatch || diseaseMatch || vIdMatch;
    });
    setFilteredData(filteredData);
  }, [appointments, searchQuery]);
    const [followup, setFollowup] = useState(null);
    const [prescription, setPriscription] = useState(null);
  const [test, setTest] = useState(null);
    useEffect(() => {
      const intervalId = setInterval(() => {
        setAppointmentsFn();
      }, 5 * 60 * 1000);
      return () => clearInterval(intervalId);
    }, [setAppointmentsFn]);
  return (
    <>
     {followup && <MyPatientsFollowupModal followupData={followup} setFollowup={setFollowup} user={user}/>}
     {test && <MyPatientsTestModal setTest={setTest} testData={test} user={user}/>}
     {prescription && <MyPatientsPrescripitonModal prescriptionData={prescription} setPriscription={setPriscription} user={user}/>}
      <div className="flex justify-between items-center">
        <div className="flex font-semibold text-lg">Appointments</div>
        <div className="flex">
          <div className="flex  ">
            <SearchInput placeholder={"Search by Name, Id"} searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
          </div>
        </div>
      </div>
      <div className="flex  w-full h-full mt-5 justify-start items-center ">
        <AppointmentMode selected={selected} setSelected={setSelected} />
      </div>
      {filteredData?.length > 0 ? (
        <AppointmentsTable datas={filteredData} setFollowup={setFollowup} setTest={setTest} setPrescription={setPriscription}  />
      ) : (
        <div className="flex mt-10">
          <NoDataFound data={"appointments"} />
        </div>
      )}
    </>
  );
}

export default AppointmentsMainSection