import React from 'react'
import VideoSvg from './VideoSvg';

const VideoConsulting = () => {
  return (
    <div className={`dashboard-card-container bg-[#22A0EB] `}>
      <div className={`dashboard-card-icon bg-[#49B9FB] `}>{<VideoSvg />}</div>
      <div className="dashboard-card-content">
        <span className="dashboard-card-value">{16.3}k</span>
        <span className="dashboard-card-text">{"Video Consulting"}</span>
      </div>
    </div>
  );
}

export default VideoConsulting