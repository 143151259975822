import React from 'react'
import "./Earnings.css"
import { EarningsSvg, LockIconSvg } from '../../../svg';

const Earnings = () => {
  return (
    <div className="earnings">
      <EarningsSvg />
      <div className="earnings-content">
        <div className="lock-icon">
          <LockIconSvg />
        </div>
        <div className="text-content">
          <div> 
            ₹ <span>{16.3}</span>k
          </div>
          <div>Earnings</div>
        </div>
      </div>
    </div>
  );
}

export default Earnings