import React from "react";
import "./WelcomeBox.css"
import { BottomIcons, SideIcons } from "./Icons";

const WelcomeBox = ({user}) => {
  return (
    <div className="welcomebox-container">
      <div className="welcomebox-content">
        <div className="welcome-text">Hello, {user?.name}</div>
        <div className="welcome-text-two">
          have a nice day and don't forget to take care of yourself
        </div>
      </div>
      <img
        className="welcomebox-image"
        src="/assets/images/doctor_dashboard_image.png"
        alt="doctor_dashboard_image"
      />
      <SideIcons />
      <BottomIcons />
    </div>
  );
};

export default WelcomeBox;
