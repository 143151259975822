import React from 'react'

const EachWeak = ({ startDate }) => {
      const dayNames = ["S", "M", "T", "W", "T", "F", "S"];
      const currentWeekDates = Array.from({ length: 7 }, (_, i) => {
        const date = new Date(startDate);
        date.setDate(startDate.getDate() + i);
        return date.toLocaleDateString("en-US", { day: "2-digit" });
      });
  return (
    <div className="cs-each-weak">
      {dayNames.map((day, index) => (
        <div key={index} className="cs-each-weak-day">
          <div className="flex">{day}</div>
          <div className="flex">{currentWeekDates[index]}</div>
        </div>
      ))}
    </div>
  );
}

export default EachWeak