import React from 'react'
import CalendarSvg from './CalendarSvg';

const AppointmentCard = () => {
  return (
    <div className={`dashboard-card-container bg-[#7A6EFE] `}>
      <div className={`dashboard-card-icon bg-[#9188FF] `}>
        {<CalendarSvg />}
      </div>
      <div className="dashboard-card-content">
        <span className="dashboard-card-value">{24.4}k</span>
        <span className="dashboard-card-text">{"Appointment"}</span>
      </div>
    </div>
  );
}

export default AppointmentCard