import { useState } from "react";
import "./CalenderSection.css";
import { GoTriangleLeft, GoTriangleRight } from "react-icons/go";
import NextWeakSection from "./NextWeakSection";
import EachWeak from "./EachWeak";

const CalenderSection = () => {
  const options = { year: "numeric", month: "short", day: "2-digit" };
  const [currentDate, setCurrentDate] = useState(new Date());
  const formattedDate = currentDate.toLocaleDateString("en-US", options);
  const moveWeek = (delta) => {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() + delta * 7);
    setCurrentDate(newDate);
  };

  const currentDayOfWeek = currentDate.getDay();
  const startDate = new Date(currentDate);
  startDate.setDate(currentDate.getDate() - currentDayOfWeek);


  return (
    <div className="calender-section-container relative">
      <div className="cs-header-section">
        <div className="flex text-lg font-semibold">{formattedDate}</div>
        <div className="cs-weak-change-buttons">
          <button
            className="cs-weak-change-button"
            onClick={() => moveWeek(-1)}
          >
            <GoTriangleLeft />
          </button>
          <button className="cs-weak-change-button" onClick={() => moveWeek(1)}>
            <GoTriangleRight />
          </button>
        </div>
      </div>
      <EachWeak startDate={startDate}/>
      <NextWeakSection/>
    </div>
  );
};

export default CalenderSection;
