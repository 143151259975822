import React, { useRef, useState } from 'react'
import { GrFormClose } from 'react-icons/gr';
import { useForm } from '../../../hooks';
import { DateInput } from '../../Common/FormComponents';
import JoditEditor,{Jodit} from 'jodit-react';
import { addPrescription } from '../../../utils/myPatientsUtils/myPatientsUtils';

const descriptionConfig = {
  readonly: false,
  placeholder: "Description",
  uploader: {
    insertImageAsBase64URI: true,
  },
  controls: {
    paragraph: {
      list: Jodit.atom({
        p: "Pharagraph",
        h1: "Heading 1",
        h2: "Heading 2",
        h3: "Heading 3",
        h4: "Heading 4",
        h5: "Heading 5",
        h6: "Heading 6",
        blockquote: "Quote",
        div: "Div",
        pre: "Source code",
      }),
    },
  },
};
const prescriptionConfig = { readonly: false, placeholder: "Prescription" };
const MyPatientsPrescripitonModal = ({ prescriptionData = null, setPriscription, user }) => {
    const prescriptionInitialState = {
      patient_name: prescriptionData?.patient_name || "",
      patient_id: prescriptionData?.patient_id || "",
      phone_number: prescriptionData?.phone_number || "",
      email: prescriptionData?.email || "",
      age: prescriptionData?.age || "",
      date: "",
    };
    const editor = useRef(null);
    const [description, setDescription] = useState("<p></p>");
    const [prescription, setPrescription] = useState("<p></p>");
    const [prescriptionFormData, hanldePrescriptionData] = useForm(prescriptionInitialState);
    const [loading,setLoading] = useState(false);
    const [err, setErr] = useState({ global: "" });
    const handleSubmit = (e) => {
        e.preventDefault();
        const datePattern = /^\d{4}-\d{2}-\d{2}$/;
        if (!datePattern.test(prescriptionFormData.date)) {
            setErr({ global: "Invalid Date" });
            return;
        }
        if (!prescription.replace(/<[^>]*>/g, "").trim().length) {
          setErr({ global: "Invalid Prescription" });
          return;
        }
        if (!description.replace(/<[^>]*>/g, "").trim().length) {
            setErr({ global: "Invalid Description" });
            return;
        }
        setErr({ global: "" });
        setLoading(true);
        addPrescription(prescriptionFormData,prescription,description,user?._id)
          .then(res => { setPriscription(null) })
          .catch(err => console.log(err))
          .finally(() => setLoading(false));
    };
  return (
    <div className="absolute flex w-full h-fit top-0 left-0 bottom-0 justify-center items-center p-4 ">
      <div className="flex bg-white w-full max-w-screen-md overflow-y-auto h-full border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col">
        <div className="flex w-full h-fit items-center justify-between font-semibold">
          <span>{"Add Prescription"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setPriscription(false);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <DateInput
            name={"Date"}
            formName={"date"}
            data={prescriptionFormData}
            handleInput={hanldePrescriptionData}
            setError={setErr}
          />
        </div>
        <div className="flex flex-col w-full gap-0.5">
          <label htmlFor={"Prescription"} className="label">
            {"Prescription"} <span className="text-red-600">*</span>
          </label>
          <JoditEditor
            ref={editor}
            value={prescription}
            config={prescriptionConfig}
            tabIndex={1}
            onBlur={(newContent) => {
              setPrescription(
                newContent.replace(
                  /<td(?:[^>]*)style="[^"]*"(?:[^>]*)>/g,
                  '<td style="border: 1px solid black;" >'
                )
              );
            }}
          />
        </div>
        <div className="flex flex-col w-full gap-0.5">
          <label htmlFor={"Description"} className="label">
            {"Description"} <span className="text-red-600">*</span>
          </label>
          <JoditEditor
            ref={editor}
            value={description}
            config={descriptionConfig}
            tabIndex={1}
            onBlur={(newContent) => {
              setDescription(
                newContent.replace(
                  /<td(?:[^>]*)style="[^"]*"(?:[^>]*)>/g,
                  '<td style="border: 1px solid black;" >'
                )
              );
            }}
          />
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setPriscription(false);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyPatientsPrescripitonModal