import React from 'react'
import {  AppointmentsMainSection } from '../../components/AppointmentsComponents';

const Appointments = () => {
  return (
    <div className="w-full h-fit px-2 pt-10 sm:p-10 relative">
      <AppointmentsMainSection />
    </div>
  );
}

export default Appointments