import React from 'react'

const NextWeakSection = () => {
  return (
    <>
      <div className="next-weak-section">
        <img
          src="/assets/svgs/calender_bg.svg"
          className="next-weak-section-bgImage"
          alt=""
        />
        <div className="nw-section-content">
          <div className="flex flex-col items-center">
            <span className="text-xl font-bold">Next Week</span>
            <span className="text-sm font-semibold">Upcomming Schedules-2</span>
          </div>
          <div className="flex gap-2">
            <button className="nw-section-button bg-blue-600">View</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default NextWeakSection