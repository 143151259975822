import React, { useCallback, useEffect, useState } from 'react'
import "./TodaysAppointment.css"
import { GoTriangleRight } from 'react-icons/go';
import AppointedUser from './AppointedUser/AppointedUser';
import { getTodaysAppointments } from '../../../../../utils/appointmentsUtils/appointmentsUtils';
import { NoDataFound } from '../../../../Common';
import { useNavigate } from 'react-router-dom';

const TodaysAppointment = ({ user }) => {
  const [appointments, setAppointments] = useState([]);
  const setTodaysAppointmentsFn = useCallback(() => {
    getTodaysAppointments(user?._id).then(res => setAppointments(res.data.data)).catch(err => console.log(err));
  }, [user?._id]);
  useEffect(() => setTodaysAppointmentsFn(), [setTodaysAppointmentsFn]);
  const navigate = useNavigate()
  return (
    <div className="todays-appointment-container">
      <div className="todays-appointment-header">
        <div className="ta-header-text">Todays Appointment</div>
        <div className="ta-header-link">
          <span onClick={() => navigate(`/appointments`)}>View All</span>
          <span>
            <GoTriangleRight />
          </span>
        </div>
      </div>
      <div className="ta-users-list">
        {appointments.length > 0 ? (
          appointments.map((appointment) => (
            <AppointedUser
              key={appointment._id}
              name={appointment.name}
              time={appointment.time}
            />
          ))
        ) : (
          <NoDataFound data={"appointments"} />
        )}
      </div>
    </div>
  );
}

export default TodaysAppointment