import { Route, Routes } from "react-router-dom";
import Layout from "./layout/Layout";
import { Dashboard,Appointments, MyPatients, OnlineAppointments, Login, Profile } from "./pages";
import { AuthProtected, UserProtected } from "./components/ProtectedRoutes/UserProtected";
import { useDispatch } from "react-redux";
import { useCallback, useEffect } from "react";
import { saveUser } from "./redux/userSlice";



function App() {
  const dispatch = useDispatch();
  const saveUserFromLocalStorage = useCallback(() => {
    const doctor = JSON.parse(localStorage.getItem("doctor"));
    if (doctor) {
      dispatch(saveUser(doctor));
    }
  }, [dispatch]);
  useEffect(() => {
    saveUserFromLocalStorage();
  }, [saveUserFromLocalStorage]);
  return (
    <Routes>
      <Route path="/" element={<UserProtected element={<Layout />}/>}>
        <Route index={true} element={<Dashboard/>} />
        <Route path="/appointments" element={<Appointments />} />
        <Route path="/my-patients" element={<MyPatients />} />
        <Route path="/online-appointment" element={<OnlineAppointments />} />
        <Route path="/profile" element={<Profile/>} />
      </Route>
      <Route path="/login" element={<AuthProtected element={<Login />}/>} />
    </Routes>
  );
}

export default App;
