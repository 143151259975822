import { axiosInstance } from '../../api/config';
import { store } from '../../redux/store';
import { saveUser } from '../../redux/userSlice';

export const handleLogin = async (initialState, setErr) => {
  try {
    const response = await axiosInstance
      .post(
        `/doctor/login`,
        {
          doctor_id: initialState.doctorId.trim(),
          password: initialState.password,
        },
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        const { token } = response.data.data;
        const user = JSON.stringify(response.data.data);
        localStorage.setItem('accessToken', token);
        localStorage.setItem('doctor', user);
        store.dispatch(saveUser(response.data.data));
      });
    return response;
  } catch (error) {
    setErr({ global: 'Credentails Not Matching' });
    throw error;
  }
};


export const verifyEmail = async (email, setErr) => {
  try {
    const response = await axiosInstance.post(`/doctor-forgotpasswordemailverify`, { email }, { headers: { "Content-Type": "application/json" } });
    return response;
  } catch (error) {
    setErr({ global: "Email Not Matching" });
    throw error;
  }
}

export const verifyOtp = async (email, otpforgotPassword, setErr) => {
  try {
    const response = await axiosInstance.post(`/doctor-forgotpasswordotpverify`, { email,otpforgotPassword }, { headers: { "Content-Type": "application/json" } });
    return response;
  } catch (error) {
    setErr({ global: "Otp Not Matching" });
    throw error;
  }
}

export const submitNewPassword = async(email, password, setErr) => {
  try {
    const response = await axiosInstance.post(`/doctor-forgotpasswordotpset`, { email, password }, { headers: { "Content-Type": "application/json" } });
    return response;
  } catch (error) {
    setErr({ global: "Password Change Failed" });
    throw error;
  }
}