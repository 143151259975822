import React, { useEffect, useState } from 'react';
import { verifyOtp } from '../../../utils/loginUtils/loginUtils';
import { TiArrowLeft } from 'react-icons/ti';


const OtpField  = ({ setErrors , err, loading, setLoading,loginData,clearForm, setForgot,setEmailVerfied, setOtpVerified}) => {
  const initialOtpDigits = Array.from({ length: 4 }, () => "");
  const [enteredOtp, setEnteredOtp] = useState("");
  const [otpDigits, setOtpDigits] = useState(initialOtpDigits);
  const handleInputChange = (index, value) => {
    const sanitizedValue = /^[0-9]{0,1}$/.test(value) ? value : '';
    setOtpDigits((prevDigits) => {
      const updatedDigits = [...prevDigits];
      updatedDigits[index] = sanitizedValue;
      return updatedDigits;
    });
  };
    useEffect(() => {
        const concatenatedOtp = otpDigits.join("");
        setEnteredOtp(concatenatedOtp);
    }, [otpDigits]);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!/^.{4}$/.test(enteredOtp)) {
      setErrors({ global: "Invalid Otp" });
      return;
    }
    setLoading(true);
    verifyOtp(loginData.email, enteredOtp, setErrors)
      .then((res) => setOtpVerified(true))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  return (
    <>
      <form onSubmit={(e) => handleSubmit(e)} className="flex w-full h-full justify-center items-center flex-col gap-8">
        <label htmlFor="otpFields" className="text-base text-shadow-black">
          Verification code<span className="text-red-600 ml-1">*</span>
        </label>
        <div className="flex justify-center items-center gap-1.5">
          {otpDigits.map((digit, index) => (
            <input
              key={index}
              type="text"
              name={`otpField-${index}`}
              id={`otpField-${index}`}
              value={digit}
              onChange={(event) => handleInputChange(index, event.target.value)}
              maxLength={1}
              onClick={() => setErrors({ global: "" })}
              className="border border-gray-300  p-2.5 text-[14px] w-[30px] h-[30px] rounded-md outline-none shadow-md"
            />
          ))}
        </div>
        <span
          onClick={() => {
            setErrors({ global: "" });
            clearForm();
            setForgot(false);
            setEmailVerfied(false);
          }}
          className="w-fit flex gap-1 items-center justify-center text-secondary text-sm cursor-pointer hover:text-primary"
        >
          <TiArrowLeft /> back
        </span>
        {err.global && (
          <div className="flex w-full h-fit text-red-600 justify-center items-center font-semibold text-sm">
            {err.global}
          </div>
        )}
        <button
          disabled={loading}
          onClick={(e) => {handleSubmit(e);}}
          className="w-fit h-fit py-1 justify-center items-center px-5 text-white bg-primary rounded-md"
        >
          {loading ? "Loading..." : "Submit"}
        </button>
      </form>
    </>
  );
}

export default OtpField;