import io from 'socket.io-client';

const doctor = JSON.parse(localStorage.getItem("doctor"));

export const sockets = io(`wss://api.thelifeplushospital.co.in/api/doctor/socket`, {
  withCredentials: true,
  auth: {
    doctorId: `6569906ac4de05ade880fa50`,
  },
});

// const user = localStorage.getItem("user");
// const baseURL = process.env.REACT_APP_SERVER_URL;

// export const sockets = io(`${baseURL}/doctorappointments/${user._id}`, {
//   withCredentials: true,
//   auth: {
//     doctorId: user._id,
//   },
// });
