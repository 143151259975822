import React from 'react'
import {  OnlineAppointmentsMainSection } from '../../components/OnlineAppointmentsComponents';

const OnlineAppointments = () => {
  return (
    <div className="w-full h-fit px-2 pt-10 sm:p-10 relative ">
      <OnlineAppointmentsMainSection />
    </div>
  );  
}

export default OnlineAppointments