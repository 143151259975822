import { useEffect, useState } from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { logout } from "../../../redux/userSlice";
import { RxAvatar } from "react-icons/rx";
import { FiLogOut } from "react-icons/fi";

export const NavDrop = ({user,links}) => {
    const dropDownItemsClasses = classNames(
    "text-[13px]", "font-normal",
    "p-2","hover:bg-gray-100",
    "flex","flex-row",
    "gap-1.5","items-center"
  );

  const [drop, setDrop] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

   const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!(event.target).closest("#drop-down")) {
        setDrop(false);
      }
    };
    window.addEventListener("mousedown", handleOutsideClick);
    return () => {
    window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <>
      <div
        className={"flex flex-row items-center gap-5  h-12 w-12 relative"}
        id={"drop-down"}
      >
        <div
          className={
            "h-full w-full border-accent-color-one profile-picture cursor-pointer"
          }
          onClick={() => setDrop((state) => !state)}
        >
          <img
            src={user?.doctor_image || ""}
            alt={"Profile"}
            className={
              "object-cover h-full w-full   rounded-full border border-accent-color-one"
            }
          />
        </div>
        {drop && (
          <div
            className={`navbar-dropdown z-50 absolute top-[68px] md:top-[79px] -right-6 md:right-0 bg-white shadow-md rounded 
          flex flex-col w-screen md:w-fit min-w-[200px]`}
            id={"second-component"}
          >
            <div
              className={
                "flex flex-row w-min justify-center gap-2.5 border p-2.5 rounded m-2.5"
              }
            >
              <div style={{ width: "40px", height: "40px" }}>
                <img
                  src={user?.doctor_image || ""}
                  alt={"Profile"}
                  className={
                    "object-cover rounded h-full w-full border-accent-color-one"
                  }
                />
              </div>
              <div className={"w-max"}>
                <p className={"text-[11px]"}>Logged in as</p>
                <p className={"text-[13px] "}>{user?.name || "User"}</p>
              </div>
            </div>
            <div className={"w-full flex flex-col cursor-pointer lg:hidden"}>
              {links.map(({ to, destination }, index) => (
                <NavLink to={to} key={index} className={dropDownItemsClasses}>
                  <div>{destination}</div>
                </NavLink>
              ))}
              {/* <NavLink to={"/earnings"} className={dropDownItemsClasses}>
                <div>Earnings</div>
              </NavLink> */}
              <NavLink to={"/profile"} className={dropDownItemsClasses}>
                <RxAvatar /> Profile
              </NavLink>
              <div
                className={dropDownItemsClasses + " text-[#FF6C6C]"}
                onClick={handleLogout}
              >
                <FiLogOut />
                Logout
              </div>
            </div>
            <div className={"w-full hidden flex-col cursor-pointer lg:flex"}>
              <NavLink to={"/profile"} className={dropDownItemsClasses}>
                <RxAvatar /> Profile
              </NavLink>
              <div
                className={dropDownItemsClasses + " text-[#FF6C6C]"}
                onClick={handleLogout}
              >
                <FiLogOut />
                Logout
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
