export const validateDoctorForm = (initialState, department_id, subdepartment_id, degreeData, specialities, profileImage, coverImage, setError) => {
  const namePattern = /^[A-Za-z\s.]+$/;
  const genderPattern = /^(?!Select Gender$).+/;
  const agePattern = /^\d+$/;
  const emailPattern = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i;
  const phoneNumberPattern = /^(?!0{10}$)\d{10}$/;
  const experiencePattern = /^\d+$/;

  if (!namePattern.test(initialState.name)) {
    setError({ global: "Invalid Name" });
    return false;
  }

  if (!agePattern.test(initialState.age) || parseInt(initialState.age) < 18) {
    setError({ global: "Age must be a number and at least 18" });
    return false;
  }

  if (!genderPattern.test(initialState.gender)) {
    setError({ global: "Please Select Gender" });
    return false;
  }

  if (!parseInt(initialState.price) < 0) {
    setError({ global: "Invalid Consultation Fees" });
    return false;
  }

  if (!emailPattern.test(initialState.email)) {
    setError({ global: "Invalid Email" });
    return false;
  }

  if (!phoneNumberPattern.test(initialState.phone)) {
    setError({ global: "Invalid Phone Number" });
    return false;
  }

  if (department_id === 0) {
    setError({ global: "Please Select Department" });
    return false;
  }

  if (subdepartment_id === 0) {
    setError({ global: "Please Select Treatment" });
    return false;
  }

  if (!experiencePattern.test(initialState.experience)) {
    setError({ global: "Please Select Experience" });
    return false;
  }

  if (!degreeData.length) {
    setError({ global: "Please Add At Least One Degree" });
    return false;
  }

  if (!specialities.length) {
    setError({ global: "Please Add At Least One Speciality" });
    return false;
  }

  if (!profileImage) {
    setError({ global: "Please Add Profile Image" });
    return false;
  }

  if (!coverImage) {
    setError({ global: "Please Add Cover Image" });
    return false;
  }

  return true;
}