import { axiosInstance } from "../../api/config"

export const getDepartments = async () => {
    try {
        const response = await axiosInstance.get("/department/get_department");
        return response;
    } catch (error) {
        throw error;
    }
}
