import React, { useEffect, useState } from 'react'
import { getDepartments } from '../../utils/departmentUtils/departmentUtils';
import { useForm } from '../../hooks';
import { validateDoctorForm } from '../../utils/doctorUtils/validateDoctorForm';
import { addDoctor } from '../../utils/doctorUtils/doctorUtils';
import { toast } from 'react-toastify';
import { AddButton, ImageInput, MapDegree, MapSpecialities, NumberInput, SelectInput, ShowImage, TextAreaInput, TextInput } from '../Common/FormComponents';
import { GrFormClose } from 'react-icons/gr';
import { getTreatments } from '../../utils/treamentUtils/treatmentUtils';

const ProfileModal = ({setOpen,editData}) => {
  const [departments, setDepartments] = useState([]);
  const [treatments, setTreatments] = useState([]);
  const initialState = {
    name: editData?.name || "",
    department: editData?.department_id?.department || "Select Department",
    subdepartment_id: editData?.subdepartment_id?.sub_department_name || "Select Treatment",
    email: editData?.email || "",
    phone: editData?.phone_number || "",
    registration: editData?.registration || "",
    about_doctor: editData?.about_doctor || "",
    experience: editData?.experience || "",
    price: editData?.price || "",
    gender: editData?.gender || "Select Gender",
    age: editData?.age || "",
  };
  const [formData, handleInput, clearForm] = useForm(initialState);
  const [deps, setDeps] = useState([]);
  const [dpId, setDpId] = useState(0);
  useEffect(() => {
    getDepartments().then((res) => {
      setDepartments([{ department: "Select Department", _id: 0 }, ...res.data.data]);
      setDeps(["Select Department", ...res.data.data.map((item) => item.department)]);
    }).catch(err => {
      setDepartments([{ department: "Select Department", _id: 0 }]);
    })
  }, []);
  useEffect(() => {
    const foundDepartment = departments.find(
      (dep) => dep.department === formData.department
    );
    if (foundDepartment) {
      setDpId(foundDepartment._id);
    }
  }, [departments, formData.department]);
  const [treats, setTreats] = useState([]);
  const [treatId, setTreatId] = useState(0);
  useEffect(() => {
    getTreatments()
      .then((res) => {
        setTreatments([
          { sub_department_name: "Select Treatment", _id: 0 },
          ...res.data.data,
        ]);
        setTreats([
          "Select Treatment",
          ...res.data.data.map((item) => item.sub_department_name),
        ]);
      })
      .catch((err) => {
        setTreatments([{ sub_department_name: "Select Treatment", _id: 0 }]);
      });
  }, []);
  useEffect(() => {
    const foundTreatment = treatments.find(
      (dep) => dep.sub_department_name === formData.subdepartment_id
    );
    if (foundTreatment) {
      setTreatId(foundTreatment._id);
    }
  }, [treatments, formData.subdepartment_id]);
  const [loading, setLoading] = useState(false);
  const degreeInitialState = {degree: "",year: 2023};
  const [degreeData, setDegreeData] = useState(editData?.qualification || []);
  const years = Array.from({ length: new Date().getFullYear() + 1 - 1970 },  (_, i) => 1970 + i).reverse();
  const [err, setError] = useState({global:"",speciality:"",degree:"", topTreatment: "", bestKnowFor: "", expertise:""});
  const [degreeFormData, handleDegreeInput, clearDegreeForm] = useForm(degreeInitialState);
  const handleDegree = (action, idx=0) => {
  if (action === "add") {
    if (!degreeFormData.degree.trim().length) {
      return setError({degree:"Please enter a Degree"});
    } else {
      setDegreeData([
        ...degreeData,
        { degree: degreeFormData.degree, year: degreeFormData.year },
      ]);
      clearDegreeForm();
    }
  } else if (action === "remove") {
    setDegreeData(degreeData.filter((_, index) => index !== idx));
  }
  };
  const [specialities,setSpecialites] = useState(editData?.speciality || []);
  const [speciality, handleSpecialityInput, clearSpecialitesFeild] = useForm({speciality:""});
  const handleSpecialities = (action, idx = 0)=>{
    if (action === "add") {
      if (!speciality.speciality.trim().length) {
        return setError({speciality:"Please Enter Specialities"});
      } else {
        setSpecialites([...specialities, speciality.speciality]);
        clearSpecialitesFeild();
      }
    } else if (action === "remove") {
      setSpecialites(specialities.filter((_, index) => index !== idx));
    }
  }
  const [topTreatments, setTopTreamtments] = useState(editData?.top_treatments || []);
  const [topTreatment, handleTopTreamtent, clearTopTreatment] = useForm({topTreament:""});
  const handleTopTreamtents = (action, idx = 0)=>{
    if (action === "add") {
      if (!topTreatment.topTreament.trim().length) {
        return setError({ topTreatment: "Please Enter Top Treatment" });
      } else {
        setTopTreamtments([...topTreatments, topTreatment.topTreament]);
        clearTopTreatment();
      }
    } else if (action === "remove") {
      setTopTreamtments(topTreatments.filter((_, index) => index !== idx));
    }
  }
  const [bestKnowFors, setBestKnownFors] = useState(editData?.doctor_best_known || []);
  const [bestKnowFor, handleBestKnownFor, clearBestKnownFor] = useForm({bestKnowFor:""});
  const handleBestKnowFors = (action, idx = 0)=>{
    if (action === "add") {
      if (!bestKnowFor.bestKnowFor.trim().length) {
        return setError({ bestKnowFor: "Please Enter Best Know For" });
      } else {
        setBestKnownFors([...bestKnowFors, bestKnowFor.bestKnowFor]);
        clearBestKnownFor();
      }
    } else if (action === "remove") {
      setBestKnownFors(bestKnowFors.filter((_, index) => index !== idx));
    }
  }
  const [expertises, setExpertises] = useState(editData?.doctor_expert || []);
  const [expertise, handleExpertise, clearEpertise] = useForm({expertise:""});
  const handleExpertises = (action, idx = 0)=>{
    if (action === "add") {
      if (!expertise.expertise.trim().length) {
        return setError({ expertise: "Please Enter Expertise" });
      } else {
        setExpertises([...expertises, expertise.expertise]);
        clearEpertise();
      }
    } else if (action === "remove") {
      setExpertises(expertises.filter((_, index) => index !== idx));
    }
  }
  const [profileImage, setProfileImage] = useState(editData?.doctor_image || null);
  const [coverImage, setCoverImage] = useState(editData?.doctor_cover_image || null);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateDoctorForm(formData, dpId,treatId, degreeData, specialities, profileImage, coverImage, setError)) return;
    setError({ global: "" });
    setLoading(true);
    addDoctor(formData, dpId,treatId, degreeData, specialities, profileImage, coverImage,topTreatments,bestKnowFors,expertises, editData?._id)
      .then(res => {
        setError({ degree: "", global: "", speciality: "", bestKnowFor:"", expertise:"", topTreatment:"" }); clearForm();
        setCoverImage(null); setProfileImage(null);
        toast(`Profile ${"updated"}`, {
          type: "success",
          autoClose: 3000,
        }); setOpen(false);
      })
      .catch(err => {
        console.log(err);
        toast(`Profile ${"update"} failed`, {
          type: "error",
          autoClose: 3000,
        });
      })
      .finally(() => setLoading(false));
  };
  return (
    <div className="absolute flex w-full h-full top-0 left-0 bottom-0 justify-center items-center p-4 z-10">
      <div className="flex bg-white w-full max-w-screen-md overflow-y-auto h-full border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col">
        <div className="flex w-full h-fit items-center justify-between font-semibold">
          <span>Edit Profile</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              clearForm();
              setOpen(false);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            data={formData}
            handleInput={handleInput}
            name={"Name"}
            formName={"name"}
            setError={setError}
          />
          <NumberInput
            data={formData}
            handleInput={handleInput}
            name={"Age"}
            formName={"age"}
            setError={setError}
            min={18}
          />
          <SelectInput
            data={formData}
            handleInput={handleInput}
            items={["Select Gender", "Male", "Female"]}
            name={"Gender"}
            formName={"gender"}
            setError={setError}
          />
          <TextInput
            data={formData}
            handleInput={handleInput}
            name={"Consultation Fee"}
            formName={"price"}
            setError={setError}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            data={formData}
            handleInput={handleInput}
            name={"Email"}
            formName={"email"}
            setError={setError}
          />
          <TextInput
            data={formData}
            handleInput={handleInput}
            name={"Phone"}
            formName={"phone"}
            setError={setError}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextAreaInput
            data={formData}
            handleInput={handleInput}
            isHide={true}
            name={"About"}
            formName={"about_doctor"}
            setError={setError}
          />
          <TextInput
            data={formData}
            handleInput={handleInput}
            name={"Registration"}
            isHide={true}
            formName={"registration"}
            setError={setError}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <SelectInput
            data={formData}
            handleInput={handleInput}
            items={deps}
            name={"Department"}
            formName={"department"}
            setError={setError}
          />
          <SelectInput
            data={formData}
            handleInput={handleInput}
            items={treats}
            name={"Treatment"}
            formName={"subdepartment_id"}
            setError={setError}
          />
          <NumberInput
            hide={true}
            data={formData}
            handleInput={handleInput}
            name={"Experience"}
            formName={"experience"}
            setError={setError}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            data={degreeFormData}
            handleInput={handleDegreeInput}
            name={"Degree"}
            formName={"degree"}
            setError={setError}
          />
          <SelectInput
            data={degreeFormData}
            handleInput={handleDegreeInput}
            items={years}
            name={"Year"}
            formName={"year"}
            setError={setError}
          />
          <AddButton handleAdd={handleDegree} />
        </div>
        {err.degree && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.degree}
          </div>
        )}
        {degreeData.length > 0 && (
          <MapDegree data={degreeData} handleData={handleDegree} />
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            data={speciality}
            handleInput={handleSpecialityInput}
            name={"Speciality"}
            formName={"speciality"}
            setError={setError}
          />
          <AddButton handleAdd={handleSpecialities} />
        </div>
        {err.speciality && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.speciality}
          </div>
        )}
        {specialities.length > 0 && (
          <MapSpecialities
            data={specialities}
            handleData={handleSpecialities}
          />
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            data={topTreatment}
            handleInput={handleTopTreamtent}
            isHide={true}
            name={"Top Treatment"}
            formName={"topTreament"}
            setError={setError}
          />
          <AddButton handleAdd={handleTopTreamtents} />
        </div>
        {err.topTreatment && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.topTreatment}
          </div>
        )}
        {topTreatments.length > 0 && (
          <MapSpecialities
            data={topTreatments}
            handleData={handleTopTreamtents}
          />
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            data={bestKnowFor}
            handleInput={handleBestKnownFor}
            name={"Best Known For"}
            formName={"bestKnowFor"}
            isHide={true}
            setError={setError}
          />
          <AddButton handleAdd={handleBestKnowFors} />
        </div>
        {err.bestKnowFor && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.bestKnowFor}
          </div>
        )}
        {bestKnowFors.length > 0 && (
          <MapSpecialities
            data={bestKnowFors}
            handleData={handleBestKnowFors}
          />
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            data={expertise}
            handleInput={handleExpertise}
            name={"Expertise"}
            formName={"expertise"}
            isHide={true}
            setError={setError}
          />
          <AddButton handleAdd={handleExpertises} />
        </div>
        {err.expertise && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.expertise}
          </div>
        )}
        {expertises.length > 0 && (
          <MapSpecialities data={expertises} handleData={handleExpertises} />
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <ImageInput
            image={profileImage}
            setImage={setProfileImage}
            imageName={"Profile Image"}
          />
          <ImageInput
            image={coverImage}
            setImage={setCoverImage}
            imageName={"Cover Image"}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          {
            <>
              <ShowImage image={profileImage} setImage={setProfileImage} />
              <ShowImage image={coverImage} setImage={setCoverImage} />
            </>
          }
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileModal