import React, { useEffect, useRef } from 'react';
import {
  WelcomeBox,
  DashboardCards,
  MainSection,
} from '../../components/DashboardComponents';
import { sockets } from '../../apiSocket/socket';
import { useSelector } from 'react-redux';

const Dashboard = () => {
  const user = useSelector((state) => state.userReducer.doctor);
  const renderAfterCalled = useRef(false); // single call api trick

  useEffect(() => {
    if (!renderAfterCalled.current) {
      sockets
        .off('doctorNotification')
        .on('doctorNotification', async (data) => {
          if (data) {
            console.log('reload page');
            // web socket strem data call notification api reload page
          }
        });
    }
    renderAfterCalled.current = true;
  }, []);
  return (
    <div className="w-full h-fit px-2 pt-10 sm:p-10">
      <WelcomeBox user={user} />
      <div className="flex w-full h-fit md:px-3 xl:px-10 pt-10">
        <DashboardCards />
      </div>
      <div className="flex w-full h-fit border mt-10 rounded-md p-5 shadow-xl">
        <MainSection user={user} />
      </div>
    </div>
  );
};

export default Dashboard;
