import React, { useState } from "react";
import { TextInput } from "../../Common/FormComponents";
import { useForm } from "../../../hooks";
import { validateLoginForm } from "../../../utils/loginUtils/validateLogin";
import { handleLogin } from "../../../utils/loginUtils/loginUtils";
import PasswordInput from "../../Common/FormComponents/PasswordInput/PasswordInput";
import { useNavigate } from "react-router-dom";
import VerifyEmail from "./VerifyEmail";
import OtpField from "./OtpFeild";
import NewPasswordField from "./NewPasswordField";

const LoginForm = () => {
  const navigate = useNavigate();
  const initialState = {
    doctorId: "",
    password: "",
    email: "",
    otp: "",
    newPassword:""
  };
  const [loginData, handleLoginData, clearForm] = useForm(initialState);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState({ global: "" });
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateLoginForm(loginData, setErr)) {
      setLoading(true);
      handleLogin(loginData, setErr)
        .then((res) => {
          navigate("/");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setLoading(false));
    }
  };
  const [forgot, setForgot] = useState(false);
  const [emailVerified, setEmailVerfied] = useState(false);
  const [otpVerfied, setOtpVerified] = useState(false);
  return (
    <>
      <div className="flex w-full h-fit justify-center items-center pt-10 font-extrabold text-2xl">
        Doctor Dashboard
      </div>
      <div className="flex w-full h-full">
        <div className="w-full h-full justify-center items-center hidden md:flex">
          <img src="/assets/images/life_plus_login.png" alt="" />
        </div>
        {!forgot && (
          <form
            onSubmit={(e) => handleSubmit(e)}
            className="flex w-full h-full justify-center items-center"
          >
              <div className="flex w-4/5 lg:w-1/2 h-fit flex-col gap-5 px-10 lg:px-0">
                <TextInput
                  data={loginData}
                  handleInput={handleLoginData}
                  name="Doctor Id"
                  formName={"doctorId"}
                  setError={setErr}
                />
                <PasswordInput
                  data={loginData}
                  handleInput={handleLoginData}
                  name="Password"
                  formName={"password"}
                  setError={setErr}
                />
                <span
                  onClick={() => {
                    setErr({ global: "" });
                    clearForm();
                    setForgot(true);
                  }}
                  className="w-full flex justify-end text-secondary text-sm cursor-pointer hover:text-primary"
                >
                  forgot password ?
                </span>
                {err.global && (
                  <div className="flex w-full h-fit text-red-600 justify-center items-center font-semibold text-sm">
                    {err.global}
                  </div>
                )}
                <button
                  disabled={loading}
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                  className="w-full h-fit py-1 justify-center items-center px-2 text-white bg-primary rounded-md"
                >
                  {loading ? "Loading..." : "Submit"}
                </button>
              </div>
          </form>
        )}
        {forgot && !emailVerified && (
          <VerifyEmail
            err={err}
            handleLoginData={handleLoginData}
            loading={loading}
            loginData={loginData}
            setEmailVerfied={setEmailVerfied}
            setErr={setErr}
            setForgot={setForgot}
            setLoading={setLoading}
            clearForm={clearForm}
          />
        )}
        {forgot && emailVerified && !otpVerfied && (
          <OtpField
            setErrors={setErr}
            err={err}
            loading={loading}
            setOtpVerified={setOtpVerified}
            setEmailVerfied={setEmailVerfied}
            setForgot={setForgot}
            setLoading={setLoading}
            loginData={loginData}
            clearForm={clearForm}
          />
        )}
        {forgot && emailVerified && otpVerfied && (
          <NewPasswordField
            err={err}
            handleLoginData={handleLoginData}
            loading={loading}
            loginData={loginData}
            setEmailVerfied={setEmailVerfied}
            setErr={setErr}
            setForgot={setForgot}
            setLoading={setLoading}
            setOtpVerified={setOtpVerified}
            clearForm={clearForm}
          />
        )}
      </div>
    </>
  );
};

export default LoginForm;
