import React from "react";
import "./SearchInput.css";

const SearchInput = ({ searchQuery,setSearchQuery, placeholder, setSearchFocus=null,searchFocus=null}) => {
  const hanldeQueryChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  }  
  return (
      <>
        <input
          type="text"
          placeholder={placeholder}
          className="search-input"
          value={searchQuery}
          onChange={(e)=>{hanldeQueryChange(e);}}
          onFocus={()=>setSearchFocus ? setSearchFocus(!searchFocus): ""}
        />
      </>
    );
};

export default SearchInput;