import React from 'react'
import "./MainSection.css";
import {
  AppointmentRequests, CalenderSection, ChartSection,
  RecentPatients, TodaysAppointment
} from './SectionComponents';

const MainSection = ({user}) => {
  return (
    <div className="flex w-full h-full flex-col gap-3">
      <div className="section-container">
        <div className="flex w-full h-fit flex-col gap-3">
          <TodaysAppointment user={user} />
          <CalenderSection />
        </div>
        <div className="flex w-full h-full flex-col gap-3">
          <ChartSection />
        </div>
        <div className=" w-full h-fit  flex md:hidden lg:flex">
          <AppointmentRequests user={user} />
        </div>
      </div>
      <div className=" w-full h-fit  hidden md:flex lg:hidden">
        <AppointmentRequests user={user} />
      </div>
      <div className=" w-full h-full flex ">
        <RecentPatients user={user}/>
      </div> 
    </div>
  );
}

export default MainSection