export const validateScheduleForm = (initialState, isRe = false, setError) => {

    const datePattern = /^\d{4}-\d{2}-\d{2}$/;
    const timePattern = /^([01]\d|2[0-3]):([0-5]\d)$/;
    const descriptionPattern = /^[A-Za-z0-9\s.,()[\]{}'"&/-_!?+$#:]+$/g;

    if (!datePattern.test(initialState.date)) {
        setError({ global: "Invalid Date" });
        return false;
    }

    if (!timePattern.test(initialState.availableTime)) {
        setError({ global: "Invalid Available Time" });
        return false;
    }

    if (!timePattern.test(initialState.remindTime)) {
        setError({ global: "Invalid Remind Time" });
        return false;
    }
    
    if (isRe && !descriptionPattern.test(initialState.reason)) {
        setError({ global: "Invalid Reason" });
        return false;
    }

    return true;
};


