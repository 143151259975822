import React from 'react'

const VideoSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="13"
      viewBox="0 0 16 12"
      fill="none"
    >
      <path
        d="M2 3C2 2.60218 2.15804 2.22064 2.43934 1.93934C2.72064 1.65804 3.10218 1.5 3.5 1.5H9.5C9.89782 1.5 10.2794 1.65804 10.5607 1.93934C10.842 2.22064 11 2.60218 11 3V9C11 9.39782 10.842 9.77936 10.5607 10.0607C10.2794 10.342 9.89782 10.5 9.5 10.5H3.5C3.10218 10.5 2.72064 10.342 2.43934 10.0607C2.15804 9.77936 2 9.39782 2 9V3ZM3.5 0.5C2.83696 0.5 2.20107 0.763392 1.73223 1.23223C1.26339 1.70107 1 2.33696 1 3V9C1 9.66304 1.26339 10.2989 1.73223 10.7678C2.20107 11.2366 2.83696 11.5 3.5 11.5H9.5C9.8283 11.5 10.1534 11.4353 10.4567 11.3097C10.76 11.1841 11.0356 10.9999 11.2678 10.7678C11.4999 10.5356 11.6841 10.26 11.8097 9.95671C11.9353 9.65339 12 9.3283 12 9V8.5L13.8 9.85C13.9114 9.93357 14.0439 9.98446 14.1826 9.99697C14.3214 10.0095 14.4608 9.98311 14.5854 9.92082C14.71 9.85853 14.8148 9.76278 14.888 9.6443C14.9612 9.52582 15 9.38928 15 9.25V2.755C15 2.61572 14.9612 2.47918 14.888 2.3607C14.8148 2.24222 14.71 2.14647 14.5854 2.08418C14.4608 2.02189 14.3214 1.99552 14.1826 2.00803C14.0439 2.02054 13.9114 2.07143 13.8 2.155L12 3.5V3C12 2.6717 11.9353 2.34661 11.8097 2.04329C11.6841 1.73998 11.4999 1.46438 11.2678 1.23223C11.0356 1.00009 10.76 0.815938 10.4567 0.690301C10.1534 0.564664 9.8283 0.5 9.5 0.5H3.5ZM12 4.75L14 3.254V8.75L12 7.25V4.75Z"
        stroke="#E5EBF6"
      />
    </svg>
  );
}

export default VideoSvg