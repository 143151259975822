import React, { useEffect, useState } from 'react'
import { GrFormClose } from 'react-icons/gr';
import { useForm } from '../../../hooks';
import { DateInput, SelectInput, TextAreaInput, TextInput, TimeInput } from '../../Common/FormComponents';
import { validateTestForm } from '../../../utils/myPatientsUtils/validateTestForm';
import { addTest, getLabCoupons, getLabTests } from '../../../utils/myPatientsUtils/myPatientsUtils';
import { NoDataFound, SearchInput } from '../../Common';
import { AiOutlineCloseCircle } from 'react-icons/ai';

const MyPatientsTestModal = ({ setTest, testData = null, user }) => {
    const myPatientsTestInitialState = {
      supportInfo: "",
      patient_id: testData?.patient_id || "", 
      patient_name: testData?.name || "",
      priority: "",
      testReason: testData?.disease || "",
      reasons: "",
      date: "",
      time: "",
      payment_method: "Select Payment Method",
      email: testData?.email || "",
      discount_coupon: "0",
    };
    const [tests, setTests] = useState([]);
    const [coupons, setCoupons] = useState([
      { _id: 0, coupon_percentage: "0", coupon_amount:0 },
    ]);
    const [selectedTests, setSelectedTests] = useState([]);
    useEffect(() => {
      getLabTests()
        .then((res) => {
          setTests(res.data.data);
        })
        .catch((err) => console.log(err));
      getLabCoupons()
        .then((res) => {
          setCoupons([
            { _id: 0, coupon_percentage: "0", coupon_amount: 0 },
            ...res,
          ]);
        })
        .catch((err) => console.log(err));
        
    }, []);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    useEffect(() => {
      const filteredData = tests?.filter((test) => {
        const nameMatch = test?.test_name
          ?.toLowerCase()
          ?.includes(searchQuery.toLowerCase());
        const tIdMatch = test?.test_id
          ?.toLowerCase()
          ?.includes(searchQuery.toLowerCase());
        const departmentMatch = test?.department
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase());
        const categoryMatch = test?.category?.category_name
          ?.toLowerCase()
          ?.includes(searchQuery.toLowerCase());
        return nameMatch || tIdMatch || departmentMatch || categoryMatch;
      });
      setFilteredData(filteredData);
    }, [tests, searchQuery]);
    const handleSelctedTests = (test, method) => {
        if (method === "add") {
            if (!selectedTests.some(item => test._id === item._id)) {
                setSelectedTests([...selectedTests, test]);
            }
            return;
        } else {
            setSelectedTests(selectedTests.filter(item => test._id !== item._id));
        }
    }
    const [myPatientsTestData, handleMyPatientsTestForm] = useForm(myPatientsTestInitialState);
    const [loading,setLoading] = useState(false);
    const [err, setErr] = useState({ global: "" });
    const handleSubmit = (e) => {
      e.preventDefault();
      if (validateTestForm(myPatientsTestData,tests, setErr)) {
        setErr({ global: "" });
        setLoading(true);
        addTest(myPatientsTestData,tests,user?._id)
          .then(res =>  setTest(null) )
          .catch(err => console.log(err))
          .finally(() => setLoading(false));
      }
    };
  return (
    <div className="absolute flex w-full h-fit top-0 left-0 bottom-0 justify-center items-center p-4 ">
      <div className="flex bg-white w-full max-w-screen-md overflow-y-auto max-h-screen h-full border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col">
        <div className="flex w-full h-fit items-center justify-between font-semibold">
          <span>{"Add Test"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setTest(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            name={"Support Info"}
            formName={"supportInfo"}
            isHide={true}
            data={myPatientsTestData}
            handleInput={handleMyPatientsTestForm}
            setError={setErr}
          />
          <SelectInput
            name={"Priority"}
            formName={"priority"}
            data={myPatientsTestData}
            handleInput={handleMyPatientsTestForm}
            items={["Select Priority", "General", "Emergency"]}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <DateInput
            name={"Date"}
            formName={"date"}
            data={myPatientsTestData}
            handleInput={handleMyPatientsTestForm}
            setError={setErr}
          />
          <TimeInput
            name={"Time"}
            formName={"time"}
            data={myPatientsTestData}
            handleInput={handleMyPatientsTestForm}
            setError={setErr}
          />
        </div>
        {selectedTests?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {selectedTests?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                key={idx}
              >
                <span>{item?.test_name}</span>
                <button
                  onClick={() => {
                    handleSelctedTests(item, "remove");
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="flex w-full">
          <SearchInput
            placeholder="Search Test"
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </div>
        {filteredData?.length > 0 ? (
          <div className="w-full overflow-y-auto min-h-[100px]">
            {filteredData.map((test, index) => (
              <div
                key={index}
                onClick={() => {
                  handleSelctedTests(test, "add");
                }}
                className={`flex cursor-pointer w-full h-fit  p-2 gap-3 items-center mb-2 border rounded-md bg-white
                }`}
              >
                <div className="flex w-[50px] h-[50px] rounded-full overflow-hidden">
                  <img
                    className="h-full w-full object-cover"
                    src={test?.test_pic}
                    alt=""
                  />
                </div>
                <div className="flex flex-col">
                  <span className="flex items-end gap-2">
                    <span className="text-base">{test?.test_name}</span> |{" "}
                    <span className="text-sm">{test?.test_id}</span>
                  </span>
                  <span className="text-xs">
                    department: {test?.department}
                  </span>
                  <span className="text-xs">
                    category : {test?.category?.category_name}
                  </span>
                  <span className="text-xs">price : ₹{test?.total_price}</span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <NoDataFound data="tests" />
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <SelectInput
            formName={"payment_method"}
            items={["Select Payment Method", "cash", "online"]}
            name="Payment Method"
            data={myPatientsTestData}
            handleInput={handleMyPatientsTestForm}
            setError={setErr}
          />
          <div className="flex flex-col gap-0.5 ">
            <label htmlFor={"Discount (%)"} className="label">
              {"Discount"}{" "}
              <span hidden={true} className="text-red-600">
                *
              </span>
            </label>
            <div
              className="input-coantiner"
              onClick={() => setErr({ global: "" })}
            >
              <select
                className="select-option"
                value={myPatientsTestData.discount_coupon.coupon_percentage}
                name={"discount_coupon"}
                onChange={handleMyPatientsTestForm}
              >
                {coupons?.map((item, index) => (
                  <option key={index} value={item._id}>
                    {item?.coupon_percentage}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextAreaInput
            name={"Reasons"}
            formName={"reasons"}
            data={myPatientsTestData}
            handleInput={handleMyPatientsTestForm}
            setError={setErr}
          />
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setTest(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyPatientsTestModal