import React, { useMemo, useState } from 'react'
import { Table } from '../../Common';
import { BiCheck } from 'react-icons/bi';
import { IoIosClose } from 'react-icons/io';
import { updateAppointment } from '../../../utils/onlineAppointmentUtils/onlineAppointmentUtils';
import NewScheduleModal from '../NewScheduleModal/NewScheduleModal';
import ReScheduleModal from '../ReScheduleModal/ReScheduleModal';

function getCurrentTime() {
  const now = new Date();
  const hours = now.getHours().toString().padStart(2, "0");
  const minutes = now.getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
}


const OnlineAppointmentsTable = ({ datas,setOnlineAppointmentsFn }) => {
      const headings = useMemo(
        () => ["Name", "P-Id", "V-ID","Phone", "Disease", "Date", "Action"],
        []
      );
      const acceptFn = (appointmentId) =>{
        updateAppointment(appointmentId, "Accepted")
        .then(res=>setOnlineAppointmentsFn())
        .catch(err=>console.log(err));
      }
      const rejectFn = (appointmentId) =>{
        updateAppointment(appointmentId, "Rejected")
        .then(res=>setOnlineAppointmentsFn())
        .catch(err=>console.log(err));
      }
  console.log(datas);
      const [selectedAppointment,setSelectedAppointment] = useState(null);
      const [newSchedule, setNewSchedule] = useState(false);
      const [reSchedule, setReSchedule] = useState(false);
  return (
    <>
      {" "}
      {newSchedule && (
        <NewScheduleModal
          setOpen={setNewSchedule}
          appointment={selectedAppointment}
          setOnlineAppointmentsFn={setOnlineAppointmentsFn}
        />
      )}
      {reSchedule && (
        <ReScheduleModal
          setOpen={setReSchedule}
          appointment={selectedAppointment}
          setOnlineAppointmentsFn={setOnlineAppointmentsFn}
        />
      )}
      <Table headings={headings}>
        {datas?.map((data, index) => (
          <tr key={index}>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm text-gray-900">{data?.name}</div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm text-gray-900 capitalize">
                {data?.patient_id}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm text-gray-900 capitalize">
                {data?.visit_id}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm text-gray-900 capitalize">
                {data?.phone_number}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm text-gray-900 capitalize">
                {data.disease}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm text-gray-900 capitalize">
                {data?.date}
              </div>
            </td>
            {!data?.isVerified ? (
              <td className="px-4 py-4 whitespace-nowrap flex gap-2 ">
                <button
                  onClick={() => rejectFn(data?._id)}
                  className="w-fit border rounded-md p-1  shadow-lg h-fit justify-center items-center active:bg-slate-200 text-red-600"
                >
                  <IoIosClose />
                </button>
                <button
                  onClick={() => acceptFn(data?._id)}
                  className="w-fit border rounded-md p-1  shadow-lg h-fit justify-center items-center active:bg-slate-200 text-blue-600"
                >
                  <BiCheck />
                </button>
              </td>
            ) : data?.isVerified === "Accepted" ? (
              <td className="px-6 py-4 whitespace-nowrap">
                {data?.schedule ? (
                  true ? (
                    <button
                      onClick={() => {
                      /* handle join logic */
                      }}
                      className="flex justify-center items-center gap-2 text-xs border rounded-md bg-green-600 text-white p-2"
                    >
                      <span>Join</span>
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setNewSchedule(false);
                        setSelectedAppointment(data);
                        setReSchedule(true);
                      }}
                      className="flex justify-center items-center gap-2 text-xs border rounded-md bg-red-600 text-white p-2"
                    >
                      <span>Re schedule</span>
                    </button>
                  )
                ) : (
                  <button
                    onClick={() => {
                      setReSchedule(false);
                      setSelectedAppointment(data);
                      setNewSchedule(true);
                    }}
                    className="flex justify-center items-center gap-2 text-xs border rounded-md bg-blue-600 text-white p-2"
                  >
                    <span>Add Schedule</span>
                  </button>
                )}
              </td>
            ) : (
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-red-600 capitalize">
                  {data?.isVerified}
                </div>
              </td>
            )}
          </tr>
        ))}
      </Table>
    </>
  );
}

export default OnlineAppointmentsTable