import React, { useState } from 'react'
import { GrFormClose } from 'react-icons/gr';
import { useForm } from '../../../hooks';
import { DateInput, TextAreaInput, TimeInput } from '../../Common/FormComponents';
import { setSchedule } from '../../../utils/onlineAppointmentUtils/onlineAppointmentUtils';
import { validateScheduleForm } from '../../../utils/onlineAppointmentUtils/validateScheduleForm';

const ReScheduleModal = ({ setOpen, appointment, setOnlineAppointmentsFn }) => {
    const [err, setErr] = useState({ global: "" });
  const [loading, setLoading] = useState(false);
    const reScheduleInitialState = {
        date: appointment?.schedule?.date ,
        availableTime: appointment?.schedule?.availableTime,
        remindTime: appointment?.schedule?.remindTime,
        reason: appointment?.schedule?.reason || ""
    };
    const [reScheduleData, handleReScheduleData, clearForm] = useForm(reScheduleInitialState);
        const handleSubmit = (e) => {
          e.preventDefault();
          if (validateScheduleForm(reScheduleData, true, setErr)) {
            setSchedule(reScheduleData, appointment?._id)
            .then(res => { setOnlineAppointmentsFn(); clearForm(); setOpen(false)})
            .catch(err => console.log(err))
            .finally(() => setLoading(false));}
        };
  return (
    <div className="absolute flex w-full h-full top-0 left-0 bottom-0 justify-center items-center p-4 ">
      <div className="flex bg-white w-full max-w-screen-md overflow-y-auto h-fit border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col">
        <div className="flex w-full h-fit items-center justify-between font-semibold">
            <span>{"Add new Schedule"}</span>
            <span className="cursor-pointer" onClick={() => { setOpen(false); }}><GrFormClose fontSize={16}/></span>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
            <DateInput name={"Date"} formName={"date"} data={reScheduleData} handleInput={handleReScheduleData} setError={setErr}/>
            <TimeInput name={"Available Time"} formName={"availableTime"} data={reScheduleData} handleInput={handleReScheduleData} setError={setErr}/>
            <TimeInput name={"Remind Time"} formName={"remindTime"} data={reScheduleData} handleInput={handleReScheduleData} setError={setErr}/>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
            <TextAreaInput name={"Reason"} formName={"reason"} data={reScheduleData} handleInput={handleReScheduleData} setError={setErr}/>
        </div>
        {err.global && (<div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">{err.global}</div>)}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center" onClick={() => {  setOpen(false);  }}>
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center " disabled={loading} onClick={(e) => {handleSubmit(e);}}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReScheduleModal