import React, { useMemo } from 'react'
import { Table } from '../../Common';

const AppointmentsTable = ({ datas, setFollowup, setTest, setPrescription }) => {
  const headings = useMemo(
    () => [ "Name", "P-ID","V-ID" ,"Disease","Date","Slot","Time", "Follow","Test","Prescription"],
    []
  );
  return (
    <Table headings={headings}>
      {datas?.map((data, index) => (
        <tr key={index}>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">{data?.name}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">{data?.patient_id}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">{data?.visit_id}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.disease}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.date}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.slot}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.time}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <button className="text-sm text-white bg-blue-500 p-1 flex items-center justify-center rounded-md" onClick={()=>setFollowup(data)}>
              Follow
            </button>
            </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <button className="text-sm text-white bg-primary p-1 flex items-center justify-center rounded-md" onClick={()=>setTest(data)}>
              Test
            </button>
            </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <button className="text-sm text-white bg-green-500 p-1 flex items-center justify-center rounded-md" onClick={()=>setPrescription(data)}>
              Prescription
            </button>
          </td>
        </tr>
      ))}
    </Table>
  );
}

export default AppointmentsTable