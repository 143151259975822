import React from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai';

const MapSpecialities = ({data,handleData}) => {
  return (
    <div className="flex w-full gap-1 flex-wrap">
      {data?.map((item, idx) => (
        <div
          className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
          key={idx}
        >
          <span>
            {item}
          </span>
          <button
            onClick={() => {
              handleData("remove", idx);
            }}
          >
            <AiOutlineCloseCircle fontSize={14} color="red" />
          </button>
        </div>
      ))}
    </div>
  );
}

export default MapSpecialities