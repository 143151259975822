import React from "react";
import { BiCheck } from 'react-icons/bi'
import {IoIosClose} from "react-icons/io"

const StatusButton = ({ accept,reject, appointmentId }) => {
    return (
      <div className="ar-user-update-buttons">
        <button
          onClick={() => reject(appointmentId)}
          className="update-button text-red-600"
        >
          <IoIosClose />
        </button>
        <button
          onClick={() => accept(appointmentId)}
          className="update-button text-blue-600"
        >
          <BiCheck />
        </button>
      </div>
    );
};

export default StatusButton;
