import React from "react";
import { ProfileMainSection } from "../../components/ProfileComponents";

const Profile = () => {
  return (
    <div className="w-full h-fit  relative">
      <ProfileMainSection />
    </div>
  );
};

export default Profile;
